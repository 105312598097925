import React from "react"
import { Button, Space, Table, Tag } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';

const ResponsesTable = ({ responses, onRemove, onAccept, pagination }) => {
	return (
		<Table
			columns={[
				{
					title: 'Naam',
					dataIndex: 'name',
					key: 'name'
				},
				{
					title: 'Datum',
					key: 'dateTime',
					dataIndex: 'dateTime'
				},
				{
					title: 'Bericht',
					key: 'text',
					dataIndex: 'text'
				},
				{
					title: 'Goedgekeurd',
					key: 'accepted',
					dataIndex: 'accepted',
					render: (accepted) => (
						<Tag color={accepted ? 'green' : 'red'}>
							{ accepted ? 'Ja' : 'Nee'}
						</Tag>
					)
				},
				{
					title: 'Action',
					key: 'action',
					className: 'column-align-right',
					render: (text, record) => {
						return (
							<Space>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<CheckOutlined />}
									onClick={() => onAccept(record)}
								/>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<DeleteOutlined />}
									onClick={() => onRemove(record)}
									danger
								/>
							</Space>
						);
					}
				}
			]}
			rowKey='_id'
			pagination={pagination}
			dataSource={responses}
		/>
	);
}
export default ResponsesTable;
