import React, { useState, useEffect, useContext } from "react"

import AdminLayout from "../../layouts/admin"
import SEO from "../../components/seo"

import ResponsesTable from "../../components/admin/responses/responsesTable"

import { ResponseContext } from "../../contexts/responseContext"

import Loader from "../../components/loader"

import { Modal, message } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const AdminResponsesPage = ({ location }) => {
  const {
    currentPage,
    total,
    pageSize,
    responses,
    responsesLoading,
    responsesError,
    getResponses,
    createResponsesItem: createItem,
    responseCreating,
    responseCreatingError,
    removeResponsesItem: removeItem,
    responseRemoving,
    responseRemovingError,
    acceptResponse,
    responseAccepting,
    responseAcceptingError,
  } = useContext(ResponseContext)

  useEffect(() => {
    getResponses()
  }, [])

  const acceptResponseItem = async responseToAccept => {
    Modal.confirm({
      title: "Weet je het zeker",
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je de reactie van '${responseToAccept.name}' wilt accepteren?`,
      onOk: async () => {
        const accepted = await acceptResponse(responseToAccept)
        if (accepted) {
          getResponses(true, 100)
        } else {
          message.error("Er ging iets mis bij het accepteren")
        }
      },
    })
  }

  const removeResponseItem = async responseToDelete => {
    Modal.confirm({
      title: "Weet je het zeker",
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je de reactie van '${responseToDelete.name}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      onOk: async () => {
        const removed = await removeItem(responseToDelete)
        if (removed) {
          getResponses(true, 100)
        } else {
          message.error("Er ging iets mis bij het verwijderen")
        }
      },
    })
  }

  return (
    <AdminLayout
      path={location.pathname}
      breadcrumbs={[{ link: "/admin/responses", title: "Reacties" }]}
    >
      <SEO title="Admin reacties" />

      <h1>Reacties</h1>

      {responsesLoading ? (
        <Loader />
      ) : (
        <ResponsesTable
          pagination={{
            simple: true,
            pageSize,
            total,
            onChange: page => getResponses(true, page),
            current: currentPage,
          }}
          responses={responses}
          onAccept={acceptResponseItem}
          onRemove={removeResponseItem}
        />
      )}
    </AdminLayout>
  )
}

export default AdminResponsesPage
